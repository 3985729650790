import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import {
  closeCookieConsentBanner,
  selectShowCookieConsentBanner,
} from "../../reducers/ConsentReducer";
import {
  getShowDoubleOptInBanner,
  setShowDoubleOptInBanner,
  getCtaCompleted,
} from "../../reducers/ActionFormReducer";
import {
  selectShowProgressTracker,
  selectMintHeader,
  setMintHeader,
} from "../../reducers/PageReducer";
import CookieConsent from "../common/Banners/CookieConsent";
import DoubleOptInBanner from "../common/Banners/DoubleOptInBanner";
import ProgressTracker from "../ProgressTracker";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function Header() {
  const org = {
    logo: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_Purple.svg",
    onClick: () => {},
    openNewTab: false,
  };

  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [atTopOfPage, setAtTopOfPage] = useState(true);
  const [showHeader, setShowHeader] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [logoSrc, setlogoSrc] = useState(org.logo);
  const showCookieConsentBanner = useSelector(selectShowCookieConsentBanner);
  const showDoubleOptInBanner = useSelector(getShowDoubleOptInBanner);
  const showProgressTracker = useSelector(selectShowProgressTracker);
  const showMintHeader = useSelector(selectMintHeader);
  const signed = useSelector(getCtaCompleted);
  const isCookieFeatureOn = useFeatureIsOn("cookie-preferences");

  const t = useTranslations("default");

  const handleScroll = () => setAtTopOfPage(window.scrollY < 11);
  const headerBackgroundColor = classNames(
    signed ? "duration-300 bg-white text-black" : "bg-white"
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (atTopOfPage) {
        dispatch(setMintHeader(false));
      }
    }
    return () => {
      isMounted = false;
    };
  }, [atTopOfPage, dispatch]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (showMintHeader) {
        setlogoSrc(
          "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_Mint.svg"
        );
        setShowHeader(true);
      } else {
        setlogoSrc(org.logo);
        setShowHeader(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [showMintHeader, logoSrc, org.logo]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && showCookieConsentBanner && !isCookieFeatureOn) {
      setShowCookieBanner(true);
    } else if (isCookieFeatureOn) {
      setShowCookieBanner(false);
    }

    return () => {
      isMounted = false;
    };
  }, [showCookieConsentBanner, isCookieFeatureOn]);

  return (
    <header className={`lg:h-20 h-12 lg:block ${showHeader ? "" : "hidden"}`}>
      {showCookieBanner && showCookieConsentBanner && (
        <CookieConsent
          closeCookieConsentBanner={() => dispatch(closeCookieConsentBanner())}
          dataTestId={"header-banner-close"}
        />
      )}
      {showDoubleOptInBanner && (
        <DoubleOptInBanner
          handleClose={() => dispatch(setShowDoubleOptInBanner(false))}
        />
      )}
      <div
        className={`w-full lg:py-5 py-2 fixed flex z-20 ${
          atTopOfPage
            ? ""
            : `border-b border-solid ${headerBackgroundColor} shadow-md h-24`
        }`}
      >
        <div className="w-full max-w-screen-desktop 2xl:px-0 lg:px-8 px-2 mx-auto my-0 h-full">
          <div className="flex items-center w-full z-20">
            {/* <!-- Org Logo--> */}
            <div className="ps-0 w-full flex lg:justify-center lg:w-auto justify-start">
              <div className="flex flex-row items-center">
                <a
                  className="cursor-pointer"
                  onClick={org.onClick}
                  href="#"
                  target={`${org.openNewTab ? "_blank" : "_self"}`}
                >
                  <img
                    src={logoSrc}
                    alt="org-logo"
                    className="lg:mt-0 lg:ms-0 mt-2 ms-2"
                    width={100}
                    height={55}
                  />{" "}
                </a>
                <div
                  className="px-6 text-xl lg:block hidden"
                  data-testid="desktop-header-tagline"
                >
                  {t("branding_tagline")}
                </div>
              </div>
            </div>
            {showProgressTracker && (
              <ProgressTracker dataTestId="header-progress-tracker" />
            )}
            {/** Side menu bar icons */}
            <div className="cursor-pointer h-6 w-6 lg:hidden">
              {openDrawer ? (
                <div onClick={() => setOpenDrawer(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x text-white stroke-current"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </div>
              ) : (
                <div onClick={() => setOpenDrawer(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <filter id="0ki7udt2sa">
                        <feColorMatrix
                          in="SourceGraphic"
                          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                        />
                      </filter>
                    </defs>
                    <g
                      fill="none"
                      fillRule="evenodd"
                      filter="url(#0ki7udt2sa)"
                      transform="translate(-331 -25)"
                    >
                      <g>
                        <path
                          d="M0 0L24 0 24 24 0 24z"
                          transform="translate(331 25)"
                        />
                        <path
                          stroke="#121212"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M4 8L20 8M4 16L20 16"
                          transform="translate(331 25)"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
